import React, { useState } from 'react';
import {Link} from 'gatsby'
import PP_Navbar from '../../../components/PP_Navbar';
import Layout from '../../../components/Layout';
import Modal from '../../../components/Modal';

function Index(props) {
    const [modalOpen, setModal] = useState(false)
    const closeModal = () => {
        setModal(false)
    }
    let styles={
        section2:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649433666/cwa/assets/images/Partner%20Program/Sales%20Advantage/about-img2_eavf2d.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        section5:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649428084/cwa/assets/images/Partner%20Program/Sales%20Advantage/wood-desk-photo1_txvq6t.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        section7:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649435403/cwa/assets/images/Partner%20Program/partner-appointments-photo_woc4l4.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }
    return (
        <div>
            <Layout location={'Partner Program'} title={'Value Added Resellers'}>
                {modalOpen ? <Modal title="Apply to be a VAR" fill={'Value Added Reseller'} closeFn={closeModal} />:null}
            <PP_Navbar location={'Resellers'}/>   
            <main className='cw-bg-greengradient py-10 px-5'>
                <h1 className='text-white font-bold text-5xl text-center'>Value Added Resellers</h1>
            </main>
            <section className='py-10 w-100'>
                <div className='mx-auto max-w-3xl text-center space-y-3'>
                    <h3 className='cw-text-green text-3xl font-bold text-center'>Partnerships with Value Added Resellers are vital to our mission here at CWA.</h3>
                    <p className='font-serif text-xl py-3 px-5 text-left leading-8'>
                        We work to ensure all of our partners are given every possible opportunity to succeed. Whether it be utilizing our many industry resources cultivated over 
                        30 years or offering individual training to you and your team, we will do whatever it takes to build TOGETHER.
                    </p>
                    <p className='font-serif text-xl py-3 px-5 text-left leading-8'>
                        Participating in our Value Added Reseller Program is the best way to market your POS solutions bundled with our payment processing services. Our program's 
                        offerings are specifically designed to make it easier for you to develop, promote, and sell POS solutions to more merchants.
                    </p>
                </div>
            </section>
            <section className="py-10 px-5" style={styles.section2}>
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div></div>
                        <div className="relative text-white max-w-md">
                            <h4 className='font-bold text-lg pb-3'>Experience</h4>
                            <p className='font-serif'>
                            CWA's headquarters is located in Syosset, NY. CWI owns Merrick Bank, providing an extremely unique advantage 
                            over our competition. Due to our affiliation with our funding bank, our merchants are given a level of service 
                            that other ISOs simply cannot match.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="cw-text-green font-bold text-lg">Residuals & Rewards</h2>
                            <p className="py-4 font-serif">
                                CWA Resellers are paid on more facets of processing than 99% of channel partners in 
                                the industry. Receive a residual payment on every aspect of a merchant's business, not just 
                                from processing volume. We pay ALL of our resellers, no quotas are needed for you to receive 
                                full compensation.
                            </p>
                        </div>
                        <div className="mx-auto">
                            <img alt='' style={{height:'auto', width:'207px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649357658/cwa/assets/images/Partner%20Program/partner-residuals_jdpifo.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-20 px-5" style={styles.section5}>
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div></div>
                        <div className="relative text-white max-w-md">
                            <h4 className='font-bold text-lg pb-3'>Sales Enablement</h4>
                            <p className='font-serif'>
                                Treat us as your own personal ad agency. Browse our list of 
                                marketing materials or simply place a request and we will customize 
                                something for you and your business. This is a great way to co-brand 
                                your business alongside CWA.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl m-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="cw-text-green font-bold text-lg">Support</h2>
                            <ul className='font-serif list-disc pt-4 pl-2'>
                                <li>24/7/365 Technical Support</li>
                                <li>8:30am-5:30pm EST Merchant Support</li>
                                <li>11:00am-8:00pm EST Dispute Manager</li>
                                <li>Online Sales Training</li>
                                <li>Individual Training</li>
                                <li>Direct access to CWA's Underwriting Team for quick turnaround on approvals</li>
                            </ul>
                        </div>
                        <div className="mx-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649434733/cwa/assets/images/Partner%20Program/merchant-support2_edaa0m.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5" style={styles.section7}>
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div></div>
                        <div className="relative text-white max-w-md">
                            <h4 className='font-bold text-lg pb-3'>Customized CRM</h4>
                            <p className='font-serif'>
                                Manage leads, make appointments, commission reports and access needed forms and VAR sheets.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="cw-bg-greengradient">
                <div className="px-5 text-center py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif max-w-xl mx-auto leading-8">Take the first step toward starting an exciting and rewarding career in our Sales Partner Program.</h3>
                    <button onClick={()=>setModal(true)} className="transition rounded ease-in-out delay-150 border-2 border-white p-2 text-white hover:bg-white hover:cw-text-green font-semibold text-lg rounded">Apply to be a VAR</button>
                </div>
            </section>
            </Layout>
        </div>
    );
}

export default Index;